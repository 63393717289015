import { auth } from '../../firebase';  // Adjust the import path to point to firebase.js
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

// Function to sign up a new user
export const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password);

// Function to log in an existing user
export const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

// Function to log out the user
export const logout = () => signOut(auth);

// Function to listen to authentication state changes
export const authStateListener = (callback) => onAuthStateChanged(auth, callback);

export default auth;