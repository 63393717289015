import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import BreadcrumbsNav from '../components/BreadcrumbsNav'; // Import BreadcrumbsNav
import ContractDetails from '../components/Contract/ContractDetails';
import DeliveryOrders from '../components/Contract/DeliveryOrders';
import { useParams } from 'react-router-dom'; // Import useParams
import { getUsaAwardDetails } from '../networking/contract/contractCalls'; // Import the function

const Contract = () => {
  const { internal_id } = useParams(); // Extract internal_id from the route parameter
  const [tabValue, setTabValue] = useState(0);
  const [awardDetails, setAwardDetails] = useState(null); // State to store award details

  const selectedDashboardTab = JSON.parse(sessionStorage.getItem('selectedDashboardTab')) || 0;

  // Fetch award details when the component mounts or when internal_id changes
  useEffect(() => {
    const fetchAwardDetails = async () => {
      try {
        const details = await getUsaAwardDetails(internal_id);
        console.log('Award Details:', details); // Log the award details
        setAwardDetails(details); // Set the fetched award details
      } catch (error) {
        console.error('Error fetching award details:', error);
      }
    };

    if (internal_id) {
      fetchAwardDetails();
    }
  }, [internal_id]); // Dependency array includes internal_id

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <BreadcrumbsNav /> {/* Add BreadcrumbsNav here */}

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Contract Tabs">
        <Tab label="Contract" />
        {selectedDashboardTab === 0 && <Tab label="Delivery Orders" />}
      </Tabs>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          {/* Pass internal_id as awardID, tabValue, and awardDetails to ContractDetails */}
          <ContractDetails awardID={internal_id} tabValue={tabValue} awardDetails={awardDetails} />
        </Grid>

        <Grid item xs={12} md={6}>
          {tabValue === 0 ? (
            <ContractDetails.RightTable awardDetails={awardDetails} /> // Pass awardDetails to RightTable
          ) : (
            <DeliveryOrders internalId={internal_id} /> // Pass internal_id to DeliveryOrders
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contract;