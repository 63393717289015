import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDashboardAwardsWithFilters } from '../networking/dash/dashCalls';
import { CONTRACT_PARENT_TYPES } from '../utils/constants/contractTypes';
import SearchBar from './TableComponents/SearchBar';
import TabControl from './TableComponents/TabControl';
import DataTable from './TableComponents/DataTable';
import LoadingSpinner from './TableComponents/LoadingSpinner';

const TabbedTable = forwardRef(({ awards, onTabChange, setAwards, selectedTab, keywords }, ref) => {
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState(''); // Holds the current search text
  const [currentPage, setCurrentPage] = useState(1);  // Tracks the page for pagination
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('Award ID');
  const [localAwards, setLocalAwards] = useState({ 0: [], 1: [], 2: [] });
  const [searchKeywords, setSearchKeywords] = useState(keywords || null); // Initialize with keywords from Dashboard

  const tableRef = useRef(null);  // Ref for the table to capture and restore scroll position
  const navigate = useNavigate();

  // Sync `keywords` passed from the parent (Dashboard)
  useEffect(() => {
    if (keywords && JSON.stringify(keywords) !== JSON.stringify(searchKeywords)) {
      setSearchKeywords(keywords); // Store Sidebar-triggered keywords
      setCurrentPage(1);  // Reset pagination when new keywords are applied
      fetchAwardsData(keywords, tabValue, 1); // Fetch data with the new keywords
    }
  }, [keywords]);

  // Save table's scroll position when an award is clicked
  const handlePrimeAwardClick = (internalId) => {
    if (tableRef.current) {
      sessionStorage.setItem('tableScrollPosition', tableRef.current.scrollTop.toString()); // Save table's scroll position
    }
    navigate(`/contract/${internalId}`);
  };

  // Save table's scroll position when navigating to a recipient
  const handleRecipientClick = (recipientName, recipientID) => {
    if (tableRef.current) {
      sessionStorage.setItem('tableScrollPosition', tableRef.current.scrollTop.toString()); // Save table's scroll position
    }
    navigate(`/entity/${recipientID}`, { state: { recipientName } });
  };

  // Restore table's scroll position after component renders
  useEffect(() => {
    const storedTableScrollPosition = sessionStorage.getItem('tableScrollPosition');
    if (storedTableScrollPosition && tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollTop = parseInt(storedTableScrollPosition, 10); // Restore the table's scroll position
      }, 0);
    }
  }, [localAwards]);

  // Load stored data and keywords from sessionStorage on component mount
  useEffect(() => {
    const storedAwards = JSON.parse(sessionStorage.getItem('awardsData'));
    const storedKeywords = JSON.parse(sessionStorage.getItem('storedKeywords'));

    if (storedAwards && storedKeywords && !localAwards[tabValue].length) {
      setLocalAwards(storedAwards);
      setSearchKeywords(storedKeywords);
      if (storedAwards[tabValue].length > 0) {
        setAwards(storedAwards[tabValue]);
      }
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentPage(1);  // Reset to the first page on tab change

    if (localAwards[newValue].length > 0) {
      setAwards(localAwards[newValue]);
    } else if (searchKeywords) {
      fetchAwardsData(searchKeywords, newValue, 1);
    }
  };

  const fetchAwardsData = async (keywords, tab, page = 1) => {
    if (!keywords || keywords.length === 0) {
      // Clear the current awards and reset local state
      setAwards([]);  // Clear displayed awards
      const clearedLocalAwards = { ...localAwards, [tab]: [] };  // Clear awards for the current tab
      setLocalAwards(clearedLocalAwards);
      sessionStorage.setItem('awardsData', JSON.stringify(clearedLocalAwards));  // Update sessionStorage with cleared awards
      sessionStorage.removeItem('storedKeywords');  // Clear stored keywords
      return;
    }

    const awardType = CONTRACT_PARENT_TYPES[tab];
    setLoading(true);

    try {
      const data = await getDashboardAwardsWithFilters(keywords, awardType, page);
      if (page === 1) {
        // Reset awards for the new query
        setAwards(data);
        const updatedLocalAwards = {
          ...localAwards,
          [tab]: data,
        };
        setLocalAwards(updatedLocalAwards);
        sessionStorage.setItem('awardsData', JSON.stringify(updatedLocalAwards));
        sessionStorage.setItem('storedKeywords', JSON.stringify(keywords));  // Store the keywords
      } else {
        // Append the new data for the next page
        setAwards((prevAwards) => [...prevAwards, ...data]);
        const updatedLocalAwards = {
          ...localAwards,
          [tab]: [...localAwards[tab], ...data],
        };
        setLocalAwards(updatedLocalAwards);
        sessionStorage.setItem('awardsData', JSON.stringify(updatedLocalAwards));
      }
    } catch (error) {
      console.error('Error fetching awards data:', error);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    getSelectedTab: () => tabValue,
    fetchAwardsData,
  }));

  useEffect(() => {
    if (searchKeywords && localAwards[tabValue].length === 0) {
      const storedAwards = JSON.parse(sessionStorage.getItem('awardsData'));
      const storedKeywords = JSON.parse(sessionStorage.getItem('storedKeywords'));

      if (storedAwards && storedKeywords) {
        setLocalAwards(storedAwards);
        setSearchKeywords(storedKeywords);
        if (storedAwards[tabValue].length > 0) {
          setAwards(storedAwards[tabValue]);
        }
      } else {
        fetchAwardsData(searchKeywords, tabValue, 1);
      }
    }
  }, [tabValue, searchKeywords]);

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      const formattedKeyword = [{ "keywords": [searchQuery.trim()] }];
      setSearchKeywords(formattedKeyword);  // Store the search keywords for pagination
      setCurrentPage(1);  // Reset pagination on new search
      fetchAwardsData(formattedKeyword, tabValue, 1);  // Fetch new data based on search input
    } else {
      console.log('Search query is empty.');
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleLoadMore = () => {
    if (!searchKeywords || searchKeywords.length === 0) {
      return;
    }
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    fetchAwardsData(searchKeywords, tabValue, nextPage);
  };

  const getFilteredAwards = () => {
    return localAwards[tabValue];
  };

  const filteredAwards = getFilteredAwards();

  const sortedAwards = [...filteredAwards].sort((a, b) => {
    if (orderBy === 'Obligated Amount') {
      const aAmount = tabValue === 0 && a['Award Amount'] === 0 ? a['Total Outlays'] : a['Award Amount'];
      const bAmount = tabValue === 0 && b['Award Amount'] === 0 ? b['Total Outlays'] : b['Award Amount'];
      return order === 'asc' ? aAmount - bAmount : bAmount - aAmount;
    }
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <Box>
      {/* Search and Tabs */}
      <Box sx={{ mt: 2, mb: 2 }}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} onSearch={handleSearch} />
      </Box>

      <TabControl tabValue={tabValue} handleTabChange={handleTabChange} />

      {/* Table for displaying data */}
      <Box sx={{ position: 'relative' }}>
        <div ref={tableRef}>  {/* Ref attached to the table's scrollable div */}
          <DataTable
            sortedAwards={sortedAwards}
            handleSort={handleSort}
            handlePrimeAwardClick={handlePrimeAwardClick}
            handleRecipientClick={handleRecipientClick}
            orderBy={orderBy}
            order={order}
            tabValue={tabValue}
          />
        </div>

        <LoadingSpinner loading={loading} />
      </Box>

      {/* Conditionally render Load More Button if there are awards */}
      {filteredAwards.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleLoadMore} disabled={loading}>
            {loading ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </Box>
  );
});

export default TabbedTable;