import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook

const RightDrawer = () => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { logout } = useAuth(); // Get the logout function from AuthContext

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleSignOutClick = () => {
    setDialogOpen(true);
  };

  const handleSignOutConfirm = () => {
    logout(); // Call the logout function
    setDialogOpen(false);
    setOpen(false); // Close the drawer
  };

  const handleSignOutCancel = () => {
    setDialogOpen(false);
  };

  const list1 = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={handleSignOutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
        {/* Add more list items here if needed */}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {list1}
      </Drawer>

      {/* Confirmation Dialog for Sign Out */}
      <Dialog
        open={dialogOpen}
        onClose={handleSignOutCancel}
        aria-labelledby="sign-out-dialog-title"
        aria-describedby="sign-out-dialog-description"
      >
        <DialogTitle id="sign-out-dialog-title">{"Sign Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="sign-out-dialog-description">
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignOutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSignOutConfirm} color="primary" autoFocus>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RightDrawer;