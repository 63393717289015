import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Entity from './pages/Entity';
import Contract from './pages/Contract';
import DeliveryOrderDetail from './pages/DeliveryOrderDetail';
import Login from './pages/Login';
import Signup from './pages/Signup';
import { AuthProvider } from './context/AuthContext';  // Import the AuthProvider
import PrivateRoute from './components/PrivateRoute';  // Import the PrivateRoute component
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <>
                    <Header />
                    <Dashboard />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/entity/:recipientID"
              element={
                <PrivateRoute>
                  <>
                    <Header />
                    <Entity />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/contract/:internal_id" // Update to match the expected parameter
              element={
                <PrivateRoute>
                  <>
                    <Header />
                    <Contract />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/contract/:contractId/delivery-order/:deliveryOrderId"
              element={
                <PrivateRoute>
                  <>
                    <Header />
                    <DeliveryOrderDetail />
                  </>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;